import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useUserNeeds2FA, useCurrentUser } from 'modules/Auth/hooks';
import { Row, Col } from 'components/Grid';
import { Link } from 'components/Link';
import { useMutationTE } from 'utils/react-query';
import { LoginPayload } from 'api/users';
import { useAuthActions } from 'modules/Auth/AuthContext';
import { renderLeftError } from 'utils/renderLeftError';
import { useDataCollection } from 'modules/AnalyticsCollection/AnalyticsCollectionProvider';
import { SSOProviders } from 'modules/Auth/components/Login/SSOProviders';
import { useUrlWithParams } from 'modules/Auth/hooks/useUrlWithParams';
import { isValidRedirectURL } from 'utils/url';
import { LoginForm } from './LoginForm';
import { Footer } from './Footer';
import { LoginBanner } from '../Banners';
import { TwoFactorLoginForm } from '../TwoFactorAuth/TwoFactorLoginForm';
import { authContainer, authContent, authWrapper, formGridWrapper, formWrapper, heading, LogoImage, logoLink, textGrey, textLink } from '../styles';

enum LoginModes {
  Login,
  TwoFA,
}

const LoginInternal = (): JSX.Element => {
  const userNeeds2FA = useUserNeeds2FA();
  const user = useCurrentUser();
  const router = useRouter();
  const { track } = useDataCollection();
  const query = router.query as { from?: string; recovery_path?: string };

  const signupLink = useUrlWithParams('/signup');

  const [loginPageMode, setLoginPageMode] = useState<LoginModes>(LoginModes.Login);
  const [isFirstStepLoginSuccessed, setIsFirstStepLoginSuccessed] = useState(false);

  const { makeLoginTE } = useAuthActions();

  const {
    dataE,
    mutate: login,
    isLoading,
  } = useMutationTE((body: LoginPayload) => makeLoginTE(body)(), {
    onSuccess: () => {
      setIsFirstStepLoginSuccessed(true);
    },
  });

  useEffect(() => {
    if (userNeeds2FA && loginPageMode !== LoginModes.TwoFA) {
      setLoginPageMode(LoginModes.TwoFA);
      setIsFirstStepLoginSuccessed(false);
    }
  }, [userNeeds2FA]);

  const resetLoginMode = useCallback(() => {
    if (loginPageMode !== LoginModes.Login) {
      setLoginPageMode(LoginModes.Login);
    }
  }, [loginPageMode]);

  useEffect(() => {
    if (user) {
      const currentUrl = new URL(window.location.href);
      const targetPath = query.recovery_path || query.from || `/${user.user_id}`;
      const redirectUrl = `${currentUrl.origin}${targetPath}`;
      // If user has verified email, redirect to the recoveryPath
      // Otherwise, RequestWatcher will handle the redirect to /pending-verification route
      if (user.is_email_verified) {
        if (isValidRedirectURL(redirectUrl)) router.push(redirectUrl);
        if (window.opener) {
          window.opener.postMessage({ type: 'login', redirectUrl }, '*');
          window.close();
        }
      }
    }
  }, [user?.is_email_verified]);

  const handleLogin = useCallback(
    (values: LoginPayload): void => {
      login(values);
    },
    [login],
  );

  const onLoginBtnClick = (btnText: string): void => {
    track(`${btnText} Clicked`, { pageName: 'login_page' });
  };

  return (
    <div className={authWrapper}>
      <div className={authContainer}>
        <Row justifyContent="center">
          <Col xs={10} md={10} lg={6}>
            <div className={authContent}>
              <div className={formGridWrapper}>
                <div className={formWrapper}>
                  <a className={logoLink} rel="noreferrer" target="_blank" href="https://clarifai.com">
                    <div className={LogoImage} data-testid="clarifai-logo" />
                  </a>
                  {loginPageMode === LoginModes.TwoFA && <TwoFactorLoginForm onBackClick={resetLoginMode} />}

                  {loginPageMode === LoginModes.Login && (
                    <>
                      <h1 className={heading}>Log in to Clarifai</h1>
                      {!(process.env.NEXT_PUBLIC_SIGN_UP_DISABLED === 'true') && (
                        <p className={textGrey}>
                          <Link href={signupLink} onClick={() => onLoginBtnClick('Signup')} data-testid="auth-signup-link">
                            <span className={textGrey}>or</span>
                            <span className={textLink}>Sign up for free.</span>
                          </Link>
                        </p>
                      )}
                      <br />
                      {renderLeftError(dataE)}
                      <LoginForm handleLoginFormSubmit={handleLogin} isLoading={isLoading || isFirstStepLoginSuccessed} />
                      <SSOProviders isLoading={isLoading || isFirstStepLoginSuccessed} recoveryPath={query.from} />

                      <Link className={textLink} onClick={() => onLoginBtnClick('Forgot password')} href="/forgot-password">
                        Forgot your password?
                      </Link>
                    </>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <div className="footer-auth-area">
          <Footer />
        </div>
      </div>
      <LoginBanner />
    </div>
  );
};

export const Login = LoginInternal;
export const testable = { LoginInternal };
